import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "./Accordion";

export default function ListUser() {
  const [users, setUsers] = useState([]);
  console.log(`${process.env.REACT_APP_API_URL}`);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}users/`)
      .then(function (response) {
        console.log(response.data);
        setUsers(response.data);
      });
  };

  const deleteUser = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}user/${id}/delete`)
      .then(function (response) {
        console.log(response.data);
        getUsers();
      });
  };

  return (
    <div>
      {/* <h1>List Users</h1>
       <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, key) => (
            <tr key={key}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.mobile}</td>
              <td>
                <Link
                  to={`user/${user.id}/edit`}
                  style={{ marginRight: "10px" }}
                >
                  Edit
                </Link>
                <button onClick={() => deleteUser(user.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}
      <ul id="cards">
        {users.map((user, key) => (
          <li className="card" id={key} key={key}>
            <div className="card-content">
              <h3 className="card-title">{user.name}</h3>
              <img
                className="cards-item__card--image"
                src={user.picture}
                alt="lorem ipsum"
                style={{ width: 100, height: 100 }}
              />
              <Accordion />
            </div>
            <div className="card-link-wrapper">
              <a href="/" className="card-link">
                Learn More
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
