import { useRef } from "react";

const AccordionItem = ({ faq, active, onToggle }) => {
  const { question, answer } = faq;

  const contentEl = useRef();

  return (
    <li className={`accordion_item ${active ? "active" : ""}`}>
      <button className="button" onClick={onToggle}>
        {question}
        <span className="control">{active ? "—" : "+"} </span>
      </button>
      <div
        ref={contentEl}
        className="answer_wrapper"
        style={
          active
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        {/* <div className="answer">{answer}</div> */}
        <div className="answer">
          <img src="img/nok.svg" height="48" width="48" alt="My Happy SVG" />
          <img src="img/sok.svg" height="48" width="48" alt="My Happy SVG" />
          <img src="img/ok.svg" height="48" width="48" alt="My Happy SVG" />
        </div>
      </div>
    </li>
  );
};

export default AccordionItem;
