export const faqs = [
  {
    question: "Stelt vragen?",
    answer:
      "<p><img src='img/nok.svg' height='48' width='48' alt='My Happy SVG'/><img src='img/sok.svg' height='48' width='48' alt='My Happy SVG'/><img src='img/ok.svg' height='48' width='48' alt='My Happy SVG'/></p>",
  },
  {
    question: "Reageert proactief op vragen?",
    answer:
      "Aperiam ab atque incidunt dolores ullam est, earum ipsa recusandae velit cumque. Aperiam ab atque incidunt dolores ullam est, earum ipsa recusandae velit cumque.",
  },
  {
    question: "Communicatie begrijpelijk?",
    answer:
      "Blanditiis aliquid adipisci quisquam reiciendis voluptates itaque.",
  },
  {
    question: "Herkent terechte persoonlijke en team verbeterpunten?",
    answer:
      "Blanditiis aliquid adipisci quisquam reiciendis voluptates itaque.",
  },
  {
    question: "Voldoende structurele voortgang?",
    answer:
      "Blanditiis aliquid adipisci quisquam reiciendis voluptates itaque.",
  },
  {
    question: "Bezit zelfbeheersing?",
    answer:
      "Blanditiis aliquid adipisci quisquam reiciendis voluptates itaque.",
  },
];
